// material-ui
// import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 */
//  * import logoDark from 'assets/images/logo-dark.svg';
// import logo from '../assets/images/logo.svg';
import logo from '../assets/images/image2vector.svg'

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ width }) => <img src={logo} alt="FiDocs" width={width} />

export default Logo
