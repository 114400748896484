import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useAuth } from '../../hooks/useAuth'
import { InputField } from '../../components/input-field'
import { CssVarsProvider } from '@mui/joy/styles'
import GlobalStyles from '@mui/joy/GlobalStyles'
import CssBaseline from '@mui/joy/CssBaseline'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import FormControl from '@mui/joy/FormControl'
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel'
import Typography from '@mui/joy/Typography'
import ColorSchemeToggle from '../../layout/color-scheme-toggle'
import customTheme from '../../theme'
import LogoSection from '../../layout/MainLayout/LogoSection'

const Login = () => {
  const { login } = useAuth()
  const { control, handleSubmit } = useForm({
    defaultValues: {
      identity: '',
      password: '',
    },
  })

  const onSubmit = async (data) => {
    await login(data.identity, data.password)
  }

  return (
    <CssVarsProvider
      defaultMode="light"
      disableTransitionOnChange
      theme={customTheme}
    >
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '40vw', // must be `vw` only
            '--Form-maxWidth': '700px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(4px)',
          backgroundColor: 'rgba(255 255 255 / 0.6)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div></div>
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: 10,
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            <LogoSection width={400} />

            <Box sx={{ my: 8, mb: 0 }}>
              <Typography component="h2" fontSize="xl2" fontWeight="lg">
                YouDocs ICP
              </Typography>
              <Typography level="body2" sx={{ my: 1, mb: 3 }}>
                Vul je inloggegevens in om verder te gaan.
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl required>
                <FormLabel>Gebruikersnaam</FormLabel>
                <Controller
                  name="identity"
                  control={control}
                  render={({ field }) => {
                    const { onChange, onBlur, value, name } = field
                    return (
                      <InputField
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        autoFocus
                        placeholder="Gebruikersnaam"
                        autoComplete="off"
                      />
                    )
                  }}
                />
              </FormControl>
              <FormControl required>
                <FormLabel>Wachtwoord</FormLabel>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => {
                    const { onChange, onBlur, value, name } = field
                    return (
                      <InputField
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        placeholder="*******"
                        type="password"
                        autoComplete="off"
                      />
                    )
                  }}
                />
              </FormControl>
              <Button type="submit" fullWidth>
                Log in
              </Button>
            </form>
          </Box>
          <Box component="footer" sx={{ py: 'auto' }}>
            <Typography level="body3" textAlign="center">
              © FiDocs B.V. {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831)',
          },
        })}
      />
    </CssVarsProvider>
  )

  // return (
  //   <Container sx={{ mt: 10 }}>
  //     <form onSubmit={handleSubmit(onSubmit)}>
  //       <Box
  //         sx={{
  //           alignItems: 'center',
  //           display: 'flex',
  //           mb: 3,
  //         }}
  //       >
  //         <Typography color="textPrimary" variant="h4">
  //           Login
  //         </Typography>
  //         <Box sx={{ flexGrow: 1 }} />
  //       </Box>
  //       <Grid container spacing={2}>
  //         <Grid item xs={12}>
  // <Controller
  //   name="identity"
  //   control={control}
  //   render={({ field }) => {
  //     const { onChange, onBlur, value, name } = field;
  //     return (
  //       <InputField
  //         onChange={onChange}
  //         onBlur={onBlur}
  //         name={name}
  //         value={value}
  //         autoFocus
  //         label="Username"
  //         autoComplete="off"
  //       />
  //     );
  //   }}
  // />
  //         </Grid>
  //         <Grid item xs={12}>
  // <Controller
  //   name="password"
  //   control={control}
  //   render={({ field }) => {
  //     const { onChange, onBlur, value, name } = field;
  //     return (
  //       <InputField
  //         onChange={onChange}
  //         onBlur={onBlur}
  //         name={name}
  //         value={value}
  //         label="Password"
  //         type="password"
  //         autoComplete="off"
  //       />
  //     );
  //   }}
  // />
  //         </Grid>
  //         <Grid item xs={12}>
  //           <Button color="info" fullWidth type="submit" variant="contained">
  //             Log In
  //           </Button>
  //         </Grid>
  //         <Grid item xs={12}>
  //           {/* <Button
  //           color="info"
  //           component={BrowserLink}
  //           to="/password-recovery"
  //           variant="text"
  //         >
  //           Forgot password
  //         </Button> */}
  //         </Grid>
  //       </Grid>
  //     </form>
  //   </Container>
  // );
}

export default Login
