import { lazy } from 'react'
// import MainLayout from '../layout/MainLayout';
import DefaultLayout from '../layout'
import Loadable from '../components/Loadable'
import { AuthGuard } from '../components/auth/auth-guard'
// import SyncRelations from '../containers/relations'

const Home = Loadable(lazy(() => import('../containers/Home')))
// const Declarations = Loadable(lazy(() => import('../containers/Declarations')))
// const Declaration = Loadable(
// lazy(() => import('../containers/Declarations/Declaration'))
// )
// const CreateInvoice = Loadable(
// lazy(() => import('../containers/invoices/create'))
// )
// const EditInvoice = Loadable(lazy(() => import('../containers/invoices/edit')))
const SyncCreditor = Loadable(
  lazy(() => import('../containers/creditors/sync'))
)
const SyncDebtor = Loadable(lazy(() => import('../containers/debtors/sync')))
const ExcelUpload = Loadable(
  lazy(() => import('../containers/imports/excel-upload'))
)
const QueueItems = Loadable(
  lazy(() => import('../containers/queue/queue-items-table'))
)
const FiscalInvoicesToProcess = Loadable(
  lazy(() => import('../containers/fiscal-invoices/to-process'))
)
const FiscalInvoicesProcessed = Loadable(
  lazy(() => import('../containers/fiscal-invoices/processed'))
)

const CreateFiscalInvoice = Loadable(
  lazy(() => import('../containers/fiscal-invoices/create'))
)

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      {/* <MainLayout /> */}
      <DefaultLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '*',
      element: <Home />,
    },
    // {
    //   path: 'declarations/:id',
    //   element: <Declaration />,
    // },
    // {
    //   path: 'declarations/:declarationId/create-invoice',
    //   element: <CreateInvoice />,
    // },
    // {
    //   path: 'declarations/:declarationId/edit-invoice/:invoiceId',
    //   element: <EditInvoice />,
    // },
    // {
    //   path: 'declarations',
    //   element: <Declarations />,
    // },
    {
      path: 'creditors/sync',
      element: <SyncCreditor />,
    },
    {
      path: 'debtors/sync',
      element: <SyncDebtor />,
    },
    // {
    //   path: 'relations/sync',
    //   element: <SyncRelations />,
    // },
    {
      path: 'imports/excel-upload',
      element: <ExcelUpload />,
    },
    {
      path: 'queue-items',
      element: <QueueItems />,
    },
    {
      path: 'fiscal-invoices/to-process',
      element: <FiscalInvoicesToProcess />,
    },
    {
      path: 'fiscal-invoices/processed',
      element: <FiscalInvoicesProcessed />,
    },
    {
      path: 'fiscal-invoices/create',
      element: <CreateFiscalInvoice />,
    },
  ],
}

export default MainRoutes
