const config = {
    basename: '',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    baseUrl: 'https://fds-ydsicp-web-prd.azurewebsites.net/api/',
    authUrl: 'https://fds-ydsicp-web-prd.azurewebsites.net/api/auth/login',
    // baseUrl: 'http://localhost:80/api/',
    // authUrl: 'http://localhost:80/api/auth/login'
};

export default config;
