
import React from 'react';
import './App.css';
import { StyledEngineProvider } from '@mui/styled-engine';
import CssBaseline from '@mui/joy/CssBaseline';
import NavigationScroll from './layout/NavigationScroll';
import Routes from './routes';

function App() {
  return (
    <StyledEngineProvider injectFirst>
        {/* <CssBaseline /> */}
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
    </StyledEngineProvider> 
  );
}

export default App;