import jwt_decode from 'jwt-decode';

const KEY = 'AUTH_TOKEN';

export const isTokenValid = (token) => {
  if (token === null) {
    return false;
  }
  const decodedToken = jwt_decode(token);

  return Date.now() <= decodedToken.exp * 1000;
}

export const getToken = () => {
  return localStorage.getItem(KEY);
}

export const setToken = (token) => {
  localStorage.setItem(KEY, token);
}

export const removeToken = () => {
  localStorage.removeItem(KEY)
}
